// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "P_hn";
export var chatgptCasesSection = "P_hr";
export var chatgptDevExpertiseSection = "P_hs";
export var chatgptIndustriesSection = "P_hl";
export var chatgptPrimeSection = "P_hj";
export var chatgptProcessSection = "P_hp";
export var chatgptQuotesSection = "P_hv";
export var chatgptServicesSection = "P_hk";
export var chatgptTechStackSection = "P_hq";
export var chatgptTrustedSection = "P_hm";
export var chatgptWhyChooseSection = "P_ht";