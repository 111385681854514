// extracted by mini-css-extract-plugin
export var chatGptExpertiseCases__between = "dy_Dy";
export var chatGptExpertiseCases__card = "dy_DD";
export var chatGptExpertiseCases__contentAll = "dy_DK";
export var chatGptExpertiseCases__contentOne = "dy_DH";
export var chatGptExpertiseCases__contentTwo = "dy_DJ";
export var chatGptExpertiseCases__description = "dy_DC";
export var chatGptExpertiseCases__imageDesktop = "dy_Dz";
export var chatGptExpertiseCases__title = "dy_DB";
export var chatGptExpertiseCases__wrapperLeft = "dy_DF";
export var chatGptExpertiseCases__wrapperRight = "dy_DG";